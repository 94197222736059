import ApiRequest from "../axiosInstance/request";
import { endpoints } from "../endpoints";
import axiosInstance from "../axiosInstance";


export const fetchAboutUs = async () => {
  try {
    const res = await ApiRequest.get(endpoints.cms.about);
    return res;
  } catch (error) {
    return error;
  }
};

export interface AboutMain {
  status: number
  data: AboutData
  message: string
}

export interface AboutData {
  _id: string
  section1_title: string
  section1_smalltitle: string
  section1_content: string
  section1_image: string
  section2_title: string
  section2_smalltitle: string
  section2_content: string
  section2_image: string
  section2_button_text: string
  section2_button_link: string
  isDeleted: boolean
  status: string
  updatedAt: string
}
export const fetchWebAbout = async () => {
  try {
    const res = await ApiRequest.get<AboutMain>(endpoints.cms.web_about);
    return res;
  } catch (error) {
    return error;
  }
};

export type Teammain = TeamMembers[]

export interface TeamMembers {
  facebooklink: string
  instagramlink: string
  twitterlink: string
  _id: string
  title: string
  profession: string
  image: string
  isDeleted: boolean
  status: string
  createdAt: string
  updatedAt: string
}

export const fetchWebAboutTeams = async () => {
  try {
    const res = await ApiRequest.get(endpoints.cms.web_about_teams);
    return res;
  } catch (error) {
    return error;
  }
};

export interface FaqList {
  status: number
  data: FaqData[]
  message: string
}

export interface FaqData {
  _id: string
  question: string
  answer: string
  status: string
  isDeleted: boolean
  createdAt: string
  updatedAt: string
}
export const faqList = async () => {
  try {
    const res = await ApiRequest.get<FaqList>(endpoints.cms.faq);
    return res;
  } catch (error) {
    return error;
  }
};

export interface HomeMain {
  status: number
  data: Data[]
  message: string
}

export interface Data {
  _id: string
  section1: Section1[]
  section1_image: string
  section2_title: string
  section2_smalltitle: string
  section2_content: string
  section2_image: string
  section2_button_text: string
  section2_button_link: string
  isDeleted: boolean
  status: string
  updatedAt: string
}

export interface Section1 {
  title: string
  smalltitle: string
  content: string
  button_text: string
  button_link: string
  _id: string
}

export const fetchHome = async () => {
  try {
    const res = await ApiRequest.get<HomeMain>(endpoints.cms.home);
    return res;
  } catch (error) {
    return error;
  }
};

export interface contactForm {
  name: string;
  email: string;
  message: string;
  phone: string;
  address: string;
}
export interface ContactResponseData {
  name: string
  email: string
  phone: string
  address: string
  message: string
  status: string
  isDeleted: boolean
  _id: string
  createdAt: string
  updatedAt: string
}
export interface ContactFormResponse {
  data: ContactResponseData[]
  status: number;
  message: string
}
export const contactFormDataPost = async (body: contactForm) => {
  try {
    const res = await ApiRequest.post(endpoints.cms.contact, body);
    return res;
  } catch (error) {
    return error;
  }
};


export interface RulesResponse {
  status: number
  data: RulesData
  message: string
}

export interface RulesData {
  _id: string
  title: string
  text1: string
  link_text1: string
  link_url1: string
  text2: string
  link_text2: string
  link_url2: string
  section1_title: string
  section1_content: string
  section2_title: string
  section2_content: string
  isDeleted: boolean
  status: string
  updatedAt: string
}
export const officialRule = async () => {
  try {
    const res = await ApiRequest.get<RulesResponse>(endpoints.cms.rules);
    return res;
  } catch (error) {
    return error;
  }
};


export interface DashboardAboutResponse {
  status: number
  data: DashboardAboutData
  message: string
}

export interface DashboardAboutData {
  _id: string
  title: string
  image: string
  version_title: string
  version_number: string
  linkarr: Linkarr[]
  isDeleted: boolean
  status: string
  updatedAt: string
}

export interface Linkarr {
  link_title: string
  link_url: string
  _id: string
}

export const DashboardAbout = async () => {
  try {
    const res = await ApiRequest.get(endpoints.cms.about);
    return res;
  } catch (error) {
    return error;
  }
};

export interface TermsResponse {
  status: number
  data: TermsData[]
  message: string
}

export interface TermsData {
  _id: string
  title: string
  slug: string
  content: string
  isDeleted: boolean
  status: string
  updatedAt: string
}

export const Terms = async (body: { slug: string }) => {
  try {
    const res = await ApiRequest.post(endpoints.cms.terms, body);
    return res;
  } catch (error) {
    return error;
  }
};

// settingGetlist

export const settingGetlist = async () => {
  try {
    const res = await ApiRequest.get<any>(endpoints.setting.settingGetlist);
    return res;
  } catch (error) {
    return error;
  }
};