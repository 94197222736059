import { settingGetlist } from "@/api/functions/cms.api";
import assest from "@/json/assest";
import styled from "@emotion/styled";
import {
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useQuery } from "react-query";

const FooterWrapper = styled(Box)`
  padding: 70px 0 100px;
  background-color: var(--color0C1138);
  @media(max-width: 1199px){
    padding: 50px 0;
  }
  .footer_row_wrap{
    @media(max-width: 1199px){
      flex-wrap: wrap;
    }
    @media(max-width: 899px){
      > *{
        margin-bottom: 30px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  .ftr_mdle_inr {
    display: flex;
    flex-wrap: wrap;
    @media(max-width: 899px){
      margin: 0 -10px;
      flex-direction: column;
      align-items: center;
    }
    @media(max-width: 479px){
      margin: 0;
    }
    .ftr_list {
      width: 50%;
      @media(max-width: 899px){
        padding: 0 10px;
        &:first-child{
          margin: 0 0 10px;
        }
      }
      @media(max-width: 479px){
       width: 100%;
       margin: 0;
       margin-bottom: 10px;
       &:last-child{
        margin-bottom: 0;
       }
      }
    }
  }
  .ftr_logo {
    width: 223px;
    display: inline-block;
    line-height: 0;
  }
  .ftr_lft {
    width: 20%;
    p {
      color: var(--white);
      font-size: 14px;
      max-width: 125px;
      margin-top: 15px;
      @media(max-width: 899px){
        max-width: 100%;
        margin-top: 10px;
      }
    }
    @media(max-width: 1199px){
      width: 26%;
    }
    @media(max-width: 899px){
      width: 100%;
      text-align: center;
    }
  }
  .ftr_list {
    padding: 0;
    margin: -10px;
    li {
      padding: 10px;
      @media(max-width: 899px){
        justify-content: center;
      }
      @media(max-width: 479px){
        padding: 0;
        margin-bottom: 10px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    a {
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: var(--white);
      &:hover,&.active{
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .ftr_midle {
    padding: 0 120px 0 75px;
    width: 55%;
    @media(max-width: 1199px){
      width: 45%;
      padding: 0 32px;
    }
    @media(max-width: 899px){
      width: 100%;
      padding: 0;
    }
  }
  .ftr_rgt {
    width: 25%;
    @media(max-width: 1199px){
      width: 29%;
    }
    @media(max-width: 899px){
      width: 100%;
      text-align: center;
    }
  }
  .social_list {
    padding: 0;
    display: flex;
    align-items: center;
    margin-top: 30px;
    @media(max-width: 899px){
      justify-content: center;
    }
    li {
      margin-right: 12px;
      width: auto;
      &:last-child {
        margin-right: 0;
      }
    }
    a{
      line-height: 0;
      transition: all 0.4s;
      &:hover{
        transform: scale(1.1);
      }
    }
  }
  .ftr_hdr {
    color: var(--white);
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .contact_link {
    display: flex;
    align-items: center;
    color: var(--white);
    font-size: 14px;
    @media(max-width: 899px){
      justify-content: center;
    }
    span {
      display: inline-block;
      margin-right: 10px;
      line-height: 0;
    }
    &:hover {
        color: rgba(255, 255, 255, 0.5);
      }
  }
`;

const Footer = () => {
  const { data: footerData } = useQuery({
    queryKey: 'footerData',
    queryFn: () => settingGetlist()
  })

  useEffect(() => {
    console.log(footerData?.data[0]?.setting_value, 'hello')
  }, [footerData])

  const router = useRouter();
  const footerItems = [
    {
      name: "Home",
      route: "/",
    },
    {
      name: "About Us",
      route: "/about",
    },
    {
      name: "FAQ",
      route: "/faq",
    },
    {
      name: "Terms & Conditions",
      route: "/terms",
    },
    {
      name: "Privacy Policy",
      route: "/privacy",
    },
  ];

  const socialLogo = [
    {
      image: `${assest.social_icon}`,
      path: `${footerData?.data[0]?.setting_value}`,
    },
    {
      image: `${assest.social_icon2}`,
      path: `${footerData?.data[1]?.setting_value}`,
    },
    {
      image: `${assest.social_icon3}`,
      path: `${footerData?.data[2]?.setting_value}`,
    },
    // {
    //   image: `${assest.social_icon4}`,
    //   path: "javascript:void(0)",
    // },
  ];

  return (
    <FooterWrapper>
      <Box className="footer_sec">
        <Container fixed>
          <Box className="footer_wrapper">
            <Stack direction="row" className="footer_row_wrap">
              <Box className="ftr_lft">
                <Link href="/" className="ftr_logo">
                  <Image
                    src={assest.ftr_logo}
                    width={223}
                    height={28}
                    alt="icon"
                  />
                </Link>
                <Typography variant="body1" className="para">
                  © Copyright 2023. All Rights Reserved.
                </Typography>
              </Box>
              <Box className="ftr_midle">
                <Box className="ftr_mdle_inr">
                  <List className="ftr_list">
                    {footerItems.map((data, index) => (
                      <>
                        {index <= 2 && (
                          <ListItem disablePadding className="ftr_list_left">
                            <Link key={data.route} href={data.route} className={router.pathname === data.route ? "active" : ""}>
                              {data.name}
                            </Link>
                          </ListItem>
                        )}
                      </>
                    ))}
                  </List>
                  <List className="ftr_list">
                    {footerItems.map((data, index) => (
                      <>
                        {index > 2 && (
                          <ListItem disablePadding className="ftr_list_left">
                            <Link key={data.route} href={data.route}>
                              {data.name}
                            </Link>
                          </ListItem>
                        )}
                      </>
                    ))}
                  </List>
                </Box>
              </Box>
              <Box className="ftr_rgt">
                <Typography variant="body1" className="ftr_hdr">
                  Contact Us
                </Typography>
                <Link
                  href={"javascript:void(0)"}
                  className="contact_link"
                >
                  <Typography variant="caption">
                    <Image src={assest.msgIcon} width={19} height={14} alt="" />
                  </Typography>
                  {footerData?.data[3]?.setting_value}
                </Link>
                <List className="social_list">
                  {socialLogo.map((data, index) => (
                    <ListItem disablePadding>
                      <Link href={data.path} key={index}>
                        <Image src={data.image} alt="" width={24} height={24} />
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>
    </FooterWrapper>
  );
};

export default Footer;
